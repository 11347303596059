import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, MessageService, Search, SharedDataService, User } from 'elbuild-ui-lib-core';
import { ChatmsgService, Cliente, LavoroCliente, LavoroClienteService } from 'jde-ui-lib';
import { forkJoin, Subscription } from 'rxjs';

import { SelectLavoroModalComponent } from './select-lavoro-modal/select-lavoro-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  //POLLING CHAT COUNT
  readonly POLLING_CHAT = 10;
  notseencount: number;
  usersubsription: Subscription;
  loggeduser: User;
  interval: any;

  //lavori
  selectedLavoro: any;
  lavoriList: LavoroCliente[] = [];
  advancedSearch = new Search(LavoroCliente.TABLE);

  showElement: boolean = false;
  selectedCliente: Cliente = new Cliente();


  constructor(private sharedDataService: SharedDataService,
    private lavoroClienteService: LavoroClienteService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private chatmsgService: ChatmsgService,
    private authService: AuthService) {}


  ngOnInit() {
    this.usersubsription = this.sharedDataService.currentLoggedUser.subscribe(data => {
      this.loggeduser = data as unknown as User;
      this.startChatPolling();
    });
    this.selectLavoro()
  }

  ngOnDestroy() {
    if (this.usersubsription) {
      this.usersubsription.unsubscribe();
    }

    this.stopChatPolling()
  }

  startChatPolling(): void {
    this.stopChatPolling();

    this.interval = setInterval(() => {
      this.checkChat();
    }, this.POLLING_CHAT * 1000);
  }

  checkChat(): void {
    this.chatmsgService.countNotseenCliente().subscribe(result => {
      if (result) this.notseencount = result.ct;
    })
  }

  stopChatPolling() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  selectLavoro() {
    this.getLavoriList();
    this.lavoroClienteService.getLavoroDefault().subscribe(res => {
      this.selectedLavoro = res
      this.sharedDataService.addCommonData('currentlavoro', res)
      this.getCliente(this.selectedLavoro.idcliente);
    });
  }

  getCliente(idcliente: string) {
    this.authService.getUser(idcliente).subscribe(res => {
      this.selectedCliente = res as unknown as Cliente;
    });
  }

  getLavoriList(): void {
    this.lavoriList = [];
    const datarequest = this.lavoroClienteService.search(this.advancedSearch);
    const countrequest = this.lavoroClienteService.count(this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe((results: any) => {
      this.advancedSearch.page.setTotal(results[1].ct);
      this.lavoriList = results[0];
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSelectLavoroModal() {
    const modal = this.modalService.open(SelectLavoroModalComponent, { centered: true, size: 'md', backdrop: 'static' });
    modal.componentInstance.lavoriList = this.lavoriList;
    modal.componentInstance.selectedLavoro = this.selectedLavoro.id;

    modal.result.then((item: LavoroCliente) => {
      if (item) {
        console.log('lavoro selezionato', item);
        this.selectedLavoro = item;
        this.sharedDataService.addCommonData('currentlavoro', item)
        this.getCliente(this.selectedLavoro.idcliente);
      }

    }, (reason) => {
      console.log('dismiss ', reason);
      this.getDismissReason(reason)
      /*Leave empty or handle reject*/

    }).catch(error => {
      console.log('modal error', error)
    });
  }

}
